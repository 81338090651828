<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">{{ totalProducts }} resultados</div>
            </div>
            <div class="view-options d-flex flex-wrap">
              <b-button
                class="mr-1 mb-2"
                variant="success"
                @click="$bvModal.show('scanner')"
              >
                <i class="fas fa-qrcode" />
              </b-button>
              <b-modal id="scanner">
                <StreamBarcodeReader @decode="onDecode" />
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Buscar producto"
              class="search-product"
            />
            <b-input-group-append v-if="filters.q">
              <b-button
                variant="outline-warning"
                @click="clearSearchbarAndResetSearch()"
              >
                Borrar
              </b-button>
            </b-input-group-append>
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <!-- Card to show the product packages avilable -->
      <div v-if="products.length <= 0">
        <b-card class="ecommerce-card mb-1" no-body>
          <b-card-body>
            <p>
              Agrega uno de los paquetes existentes para facilitar el registro
              de productos en tu punto de venta. Los productos se darán de alta
              con un precio y costo sugerido que podrás modificar en cualquier
              momento
            </p>
          </b-card-body>
        </b-card>
        <b-card>
          <b-row v-for="productPackage in packages" :key="productPackage.id">
            <b-col>
              <h6 class="item-name">
                {{ productPackage.name }}
              </h6>
              <b-link class="text-muted">
                {{ productPackage.product_package_items.length }} productos
              </b-link>
            </b-col>
            <b-col sm="auto">
              <b-button
                variant="success"
                @click="
                  showConfirmationModalForSelectedPackage(productPackage.id)
                "
              >
                <feather-icon icon="PlusIcon" />
                Seleccionar paquete
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div v-else>
        <b-card
          v-for="product in products"
          :key="product.id"
          class="ecommerce-card mb-1"
          no-body
        >
          <div class="item-img text-center d-flex justify-content-center px-1">
            <b-link
              :to="{
                name: 'store-product',
                params: {
                  id: $router.currentRoute.params.id,
                  store_product_id: product.id,
                },
              }"
            >
              <b-img
                :alt="`${product.product_attributes.name}-${product.id}`"
                fluid
                rounded
                :src="`${product.product_attributes.logo}`"
                :width="itemView === 'list-view' ? 110 : 200"
              />
            </b-link>
          </div>

          <!-- Product Details -->
          <b-card-body class="position-relative">
            <div class="item-wrapper">
              <div class="item-rating">
                <nutri-score
                  v-if="product.product_attributes.is_edible"
                  :nutritional-info="
                    product.product_attributes.nutritional_info
                  "
                  :class="{ 'mt-1': itemView === 'list-view' }"
                />
              </div>
              <div>
                <h6 class="item-price">${{ product.unit_price | money }}</h6>
                <h6 class="item-price">${{ product.unit_cost | money }}</h6>
              </div>
            </div>
            <h6 class="item-name">
              <b-link
                class="text-body"
                :to="{
                  name: 'store-product',
                  params: {
                    id: $router.currentRoute.params.id,
                    store_product_id: product.id,
                  },
                }"
              >
                {{ product.product_attributes.name }}
              </b-link>
              <b-link class="text-muted">
                {{ product.product_attributes.variant }}
              </b-link>
              <b-row>
                <b-col>
                  <b-badge
                    v-for="(
                      variant, n
                    ) in product.store_product_variant_attributes"
                    :key="`variant-${n}`"
                    class="mr-1 mt-1"
                    pill
                    variant="light-info"
                  >
                    {{
                      variant.variant_option_attributes.variant_attributes.name
                    }}: {{ variant.variant_option_attributes.option_name }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-badge class="mr-1 mt-1" pill variant="light-warning">
                {{
                  product.product_attributes.product_type === "product"
                    ? "Producto "
                    : "Servicio "
                }}
                {{ product.is_private ? "privado" : "publico" }}
              </b-badge>
            </h6>
            <b-card-text class="item-description mt-1">
              <b-badge
                v-for="(category, n) in product.product_attributes
                  .categories_names"
                :key="`category-${n}`"
                class="mr-1"
              >
                {{ category }}
              </b-badge>
            </b-card-text>
            <div class="starred">
              <i class="fa-star" :class="product.is_favorite ? 'fas' : 'far'" />
            </div>
          </b-card-body>

          <!-- Product Actions -->
          <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-cost">
                <h5
                  class="item-price text-success d-flex justify-content-between"
                >
                  <span>Precio</span> ${{ product.unit_price | money }}
                </h5>
                <h5 class="item-price d-flex justify-content-between mt-1">
                  <span>Costo</span> ${{ product.unit_cost | money }}
                </h5>
                <h5
                  class="
                    item-price
                    text-secondary
                    d-flex
                    justify-content-between
                    mt-1
                  "
                >
                  <span>Inventario</span>
                  <div>
                    <span
                      v-if="product.has_inventory"
                      :class="
                        product.inventory > 0 ? 'text-secondary' : 'text-danger'
                      "
                    >
                      {{ product.inventory }}
                    </span>
                    <span v-else> N/D </span>
                  </div>
                </h5>
              </div>
            </div>
            <b-button
              variant="primary"
              tag="a"
              class="btn-cart"
              :to="{
                name: 'edit-store-product',
                params: {
                  store_id: $route.params.id,
                  store_product_id: product.id,
                },
              }"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              <span>Editar</span>
            </b-button>
          </div>
        </b-card>
      </div>
    </section>

    <!-- Pagination -->
    <section v-if="totalProducts > filters.perPage">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :categories="categories"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :products="products"
      />
    </portal>
  </div>
</template>

<script>
import _ from "underscore"
import {
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BBadge,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import router from "@/router"
import { watch } from "@vue/composition-api"
import { mapActions } from "vuex"
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app"
import NutriScore from "@/@core/components/NutriScore.vue"
import ShopLeftFilterSidebar from "@/views/e-commerce/e-commerce-shop/ECommerceShopLeftFilterSidebar.vue"
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "@/views/stores/useStoreProducts"
import { useEcommerceUi } from "@/views/e-commerce/useEcommerce"
import { StreamBarcodeReader } from "vue-barcode-reader"

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BBadge,

    // SFC
    ShopLeftFilterSidebar,

    // Walleat
    NutriScore,

    StreamBarcodeReader,
  },
  setup(x, ctx) {
    const { filters, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const { itemView, itemViewOptions, totalProducts } = useShopUi()

    const {
      products,
      fetchStoreProducts,
      categories,
      fetchCategories,
      packages,
      fetchProductPackages,
    } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    // eslint-disable-next-line
    const fetchShopProducts = _.debounce(function () {
      if (
        /^\d*$/.test(filters.value.q) &&
        filters.value.q !== null &&
        filters.value.q !== ""
      ) {
        const barcodeWithOutLastDigit = filters.value.q.substring(
          0,
          filters.value.q.length - 1
        )
        fetchStoreProducts({
          by_sku: Number(barcodeWithOutLastDigit) || null,
          by_category: filters.value.categories || null,
          by_active_status: true,
          by_nutritional_info: filters.value.nutriScore || null,
          by_store: ctx.root.$route.params.id || null,
          meta: {
            pagination: {
              page: filters.value.page,
              per_page: filters.value.perPage,
            },
          },
        }).then((response) => {
          products.value = response.data
          totalProducts.value = response.meta.pagination.total_objects
        })
      } else if (filters.value.q !== null && filters.value.q !== "") {
        fetchStoreProducts({
          by_name: filters.value.q || null,
          by_category: filters.value.categories || null,
          by_active_status: true,
          by_nutritional_info: filters.value.nutriScore || null,
          by_store: ctx.root.$route.params.id || null,
          meta: {
            pagination: {
              page: filters.value.page,
              per_page: filters.value.perPage,
            },
          },
        }).then((response) => {
          products.value = response.data
          totalProducts.value = response.meta.pagination.total_objects
        })
      } else if (filters.value.q === null || filters.value.q === "") {
        fetchStoreProducts({
          by_active_status: true,
          by_category: filters.value.categories || null,
          by_nutritional_info: filters.value.nutriScore || null,
          by_store: ctx.root.$route.params.id || null,
          meta: {
            pagination: {
              page: filters.value.page,
              per_page: filters.value.perPage,
            },
          },
        }).then((response) => {
          products.value = response.data
          totalProducts.value = response.meta.pagination.total_objects
        })
      }
    }, 500)

    const fetchProductsCategories = () => {
      fetchCategories({
        by_active_status: true,
        by_store: router.currentRoute.params.id,
        meta: {
          pagination: {
            per_page: 10000,
          },
        },
      }).then((response) => {
        categories.value = response.data
      })
    }

    if (products.value.length <= 0) {
      const fetchProductPackagesData = () => {
        fetchProductPackages().then((response) => {
          packages.value = response.data
        })
      }
      fetchProductPackagesData()
    }

    fetchShopProducts()
    fetchProductsCategories()

    watch(
      [filters, sortBy],
      () => {
        fetchShopProducts()
      },
      {
        deep: true,
      }
    )

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,
      categories,
      packages,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  methods: {
    ...mapActions("stores", ["fetchStoreProducts"]),
    ...mapActions("productPackages", ["dispatchProductPackage"]),
    clearSearchbarAndResetSearch() {
      this.filters.q = ""
    },
    onDecode(result) {
      this.filters.q = result
      this.$bvModal.hide("scanner")
    },
    showConfirmationModalForSelectedPackage(productPackageId) {
      this.$bvModal
        .msgBoxConfirm(
          "¿Estás seguro de la selección del paquete de productos?",
          {
            title: "Mensaje de confirmación",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "SÍ",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.dispatchProductPackage({
              product_package_id: productPackageId,
              store_id: this.$route.params.id,
            }).then(() => {
              this.$swal({
                title: "Actualiza!",
                text: "Paquete de productos añadido",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then(() => window.location.reload())
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.card-img-top {
  max-width: 225px;
}

.starred {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
</style>
